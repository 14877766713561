<template>
  <Dialog :common-dialog="productDialog" :dialog-width="dialog_width">
    <template v-slot:title>
      <v-layout>
        <v-flex>
          <span class="text-capitalize" v-if="productType == 'door'"
            >select Doors</span
          >
          <span class="text-capitalize" v-else
            >select {{ lodash.lowerCase(productType) }}s</span
          >
          <v-icon class="mx-2 color-custom-blue" small>mdi-record</v-icon>
          Line Item
        </v-flex>
        <v-flex class="text-right">
          <v-btn
            style="height: 32px !important"
            class="custom-bold-button mr-2"
            depressed
            :disabled="lProcessing"
            color="cyan white--text"
            v-on:click="save_line_item()"
            >Save</v-btn
          >
          <v-btn
            style="height: 32px !important"
            class="custom-bold-button"
            depressed
            :disabled="lProcessing"
            v-on:click="$emit('close', true)"
            >Close</v-btn
          >
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:body>
      <div>
        <div
          v-if="lItemAlpha && lItemAlpha.length"
          class="d-flex mb-2"
          style="overflow-x: scroll"
        >
          <div
            style="position: relative"
            v-for="(alpha, index) in lItemAlpha"
            :key="`alphabet-${index}`"
            :class="{ 'alpha-exist': alpha.exist }"
            class="alph-listing custom-border-right"
          >
            <v-btn
              color="cyan white--text custom-bold-button"
              depressed
              tile
              :disabled="lProcessing"
              :text="alphabet == alpha.value"
              v-on:click="filter_alpha(alpha.value)"
            >
              {{ alpha.label }}
            </v-btn>
            <v-icon v-if="alpha.exist" color="red">mdi-record</v-icon>
          </div>
        </div>
        <div
          v-if="lItemCategory && lItemCategory.length"
          class="d-flex mb-2"
          style="overflow-x: scroll"
        >
          <div
            style="position: relative"
            v-for="(cat, index) in lItemCategory"
            :key="`category-${index}`"
            class="custom-border-right"
          >
            <v-btn
              color="cyan white--text custom-bold-button"
              depressed
              tile
              :disabled="lProcessing"
              :text="category == cat.value"
              v-on:click="filter_cat(cat.value)"
            >
              {{ cat.label }}
            </v-btn>
          </div>
        </div>
        <div class="d-flex mb-2">
          <div v-if="productType == 'door'">
            <v-radio-group
              v-model="request_type"
              row
              v-on:change="find_items()"
            >
              <v-radio label="All Doors" value="all" color="cyan"></v-radio>
              <v-radio
                label="Customer's Doors"
                value="customer"
                color="cyan"
              ></v-radio>
            </v-radio-group>
          </div>
          <v-text-field
            v-model="search"
            class="line-item-header-text px-0 v-slot-m-none"
            :placeholder="`Search ${lodash.lowerCase(productType)}s...`"
            dense
            filled
            solo
            :disabled="lProcessing"
            clearable
            flat
            color="cyan"
            v-on:keypress.enter="find_items()"
            style="max-width: 300px"
            v-on:click:clear="
              search = null;
              find_items();
            "
          />
          <template v-if="productType == 'door'">
            <v-autocomplete
              dense
              v-model="door_type"
              item-color="cyan"
              filled
              :items="lDoorTypeList"
              hide-details
              placeholder="Door Type"
              solo
              flat
              class="mx-2"
              color="cyan"
              style="max-width: 300px"
              v-on:click:clear="
                door_type = null;
                find_items();
              "
              clearable
            />
          </template>
          <v-btn
            style="height: 32px !important"
            class="custom-bold-button ml-2"
            depressed
            :disabled="lProcessing"
            v-on:click="find_items()"
            color="cyan white--text"
            >Search</v-btn
          >
        </div>
        <div>
          <div class="d-flex grey lighten-4">
            <div
              class="font-weight-600 py-2 text-center d-flex justify-center"
              style="width: 5%"
            >
              <v-checkbox
                v-on:change="update_all_line_item($event)"
                hide-details
                :value="select_all"
                :disabled="lProcessing"
                class="my-0 py-0 px-0 mx-0 dense-checkbox"
                color="cyan"
              />
            </div>
            <div
              class="color-custom-blue py-2 font-weight-700 text-left text-capitalize"
              :style="productType == 'door' ? 'width: 50%' : 'width: 65%'"
            >
              {{ lodash.lowerCase(productType) }}
            </div>
            <template v-if="productType == 'door'">
              <div
                class="color-custom-blue py-2 font-weight-700 text-left"
                style="width: 15%"
              >
                Size
              </div>
              <div
                class="color-custom-blue py-2 font-weight-700 text-left"
                style="width: 15%"
              >
                Serial Numer
              </div>
            </template>
            <template v-else>
              <div
                class="color-custom-blue py-2 font-weight-700 text-left"
                style="width: 15%"
              >
                Quantity
              </div>
            </template>
            <div
              class="color-custom-blue py-2 font-weight-700 text-left"
              style="width: 15%"
            >
              Unit Cost
            </div>
          </div>
          <div style="overflow-y: scroll; max-height: calc(100vh - 360px)">
            <div v-if="lProcessing" class="my-4 py-4">
              <v-progress-linear
                color="cyan"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </div>
            <div v-else-if="lDBLineItem && lDBLineItem.length">
              <div
                v-for="(row, index) in lDBLineItem"
                :key="index"
                class="d-flex my-2"
              >
                <div
                  class="font-weight-600 text-center d-flex justify-center"
                  style="width: 5%"
                >
                  <v-checkbox
                    v-model="row.status"
                    hide-details
                    class="my-0 py-0 px-0 mx-0 dense-checkbox"
                    color="cyan"
                  />
                </div>
                <div
                  class="text-left"
                  :style="productType == 'door' ? 'width: 50%' : 'width: 65%'"
                >
                  <div class="d-flex">
                    <v-avatar size="80">
                      <img
                        v-if="row?.image?.url"
                        :src="row?.image?.url"
                        :alt="row?.image?.name"
                      />
                      <img v-else :src="$defaultImage" />
                    </v-avatar>
                    <div style="width: 100%" class="ml-4">
                      <v-text-field
                        v-model="row.product"
                        class="line-item-header-text px-0"
                        :placeholder="productType"
                        dense
                        filled
                        solo
                        flat
                        color="cyan"
                      >
                      <template v-slot:append>
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-if="row.product_type == 'parts'"
                            color="cyan white--text"
                            label
                            v-bind="attrs"
                            v-on="on"
                            class="lip-type text-uppercase font-weight-600 equipment"
                          >
                            {{ row?.barcode_product ?? "N/A" }}
                          </v-chip>
                        </template>
                        Part ID
                      </v-tooltip>
                      </template>
                    </v-text-field>
                      <v-textarea
                        class="px-0"
                        v-model="row.description"
                        placeholder="Description"
                        dense
                        filled
                        solo
                        flat
                        rows="2"
                        auto-grow
                        color="cyan"
                      />
                    </div>
                  </div>
                </div>
                <template v-if="productType == 'door'">
                  <div
                    class="text-center"
                    style="width: 15%; position: relative"
                  >
                    <v-text-field
                      class="line-item-header-text px-0"
                      placeholder="Size"
                      dense
                      filled
                      solo
                      v-model="row.part_number"
                      flat
                      color="cyan"
                    />
                    <div style="position: absolute; left: 10px">
                      <template v-if="row.prev_line_item_id">
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              color="orange darken-3 white--text"
                              class="font-weight-700 text-capitalize"
                              small
                              >M</v-chip
                            >
                          </template>
                          <span>Already have Maintenance</span>
                        </v-tooltip>
                      </template>
                      <template v-if="request_type">
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              color="cyan darken-3 white--text"
                              class="ml-2 font-weight-700 text-capitalize"
                              small
                            >
                              {{ request_type?.charAt(0) }}
                            </v-chip>
                          </template>
                          <span class="text-capitalize"
                            >Type : {{ request_type }}</span
                          >
                        </v-tooltip>
                      </template>
                      <!-- <template v-if="row?.door_type_title"> </template>
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            v-on="on"
                            color="cyan white--text"
                            class="lip-type text-uppercase font-weight-600 door_type ml-2"
                            small
                            label
                            >{{ row?.door_type_title }}</v-chip
                          >
                        </template>
                        <span>Door Type</span>
                      </v-tooltip> -->
                    </div>
                  </div>
                  <div class="text-center" style="width: 15%">
                    <v-text-field
                      class="line-item-header-text px-0"
                      placeholder="Serial Numer"
                      dense
                      filled
                      solo
                      v-model="row.serial_no"
                      flat
                      color="cyan"
                    />
                  </div>
                </template>
                <template v-else>
                  <div class="text-center" style="width: 15%">
                    <v-text-field
                      class="line-item-header-text px-0"
                      placeholder="Quantity"
                      dense
                      filled
                      solo
                      type="number"
                      v-model="row.quantity"
                      flat
                      v-on:keypress="limitDecimal($event, row.quantity)"
                      color="cyan"
                    />
                  </div>
                </template>
                <div class="text-center" style="width: 15%">
                  <v-text-field
                    class="line-item-header-text px-0"
                    placeholder="Unit Cost"
                    dense
                    filled
                    solo
                    type="number"
                    v-model="row.rate"
                    flat
                    v-on:keypress="limitDecimal($event, row.rate)"
                    color="cyan"
                  />
                </div>
              </div>
            </div>
            <div v-else>
              <p
                class="m-0 py-4 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image mr-2"
                  width="30"
                />
                Uhh... There are no item at the moment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Dialog>
</template>
<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/pages/partials/Dialog.vue";
import {
  GET_LINE_ITEM,
  SET_LOADING,
} from "@/core/services/store/line.item.module";
import { ErrorEventBus } from "@/core/lib/message.lib";
import { cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "line-item-dialog-v2",
  props: {
    productType: {
      type: String,
      default: null,
    },
    isCustomDoorType: {
      type: Boolean,
      default: false,
    },
    productDialog: {
      type: Boolean,
      default: false,
    },
    forceQuoteType: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: null,
      request_type: "customer",
      select_all: null,
      category: "all",
      alphabet: "all",
      door_type: null,
    };
  },
  components: {
    Dialog,
  },
  methods: {
    find_items() {
      this.select_all = false;
      this.$store.dispatch(SET_LOADING, true);
      this.$store
        .dispatch(GET_LINE_ITEM, {
          customer: this.vCustomerId,
          type: this.productType,
          search: this.search,
          category: this.category,
          alphabet: this.alphabet,
          door_type: this.door_type,
          request_type: this.request_type,
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.$store.dispatch(SET_LOADING, false);
        });
    },
    save_line_item() {
      let selected_line_items = [];
      let status = false;
      for (let item of this.lDBLineItem) {
        if (item.status) {
          status = true;
          item.is_custom_door_type = this.isCustomDoorType;
          selected_line_items.push(item);
        }
      }
      if (!status) {
        ErrorEventBus.$emit(
          "update:error",
          `Please select ${this.lodash.lowerCase(this.productType)}.`
        );
        return false;
      }

      if (this.productType == "door") {
        console.log(selected_line_items, "selected_line_items");
        const door_type = [];
        const pre_line_item = {};

        let uuid = null;

        for (let i = 0; i < selected_line_items.length; i++) {
          const door_exist = door_type.some(
            (obj) =>
              obj?.product_id ==
                selected_line_items[i]?.door_type?.product_id &&
              obj?.request_type == this.request_type
          );

          if (!door_exist) {
            uuid = uuidv4();

            door_type.push({
              status: false,
              index: uuid,
              uuid: uuidv4(),
              id: null,
              image: {},
              door_type: [],
              part_number: null,
              order: i,
              serial_no: null,
              discount: 0,
              request_type: this.request_type,
              force_quote_type: this.forceQuoteType,
              type: selected_line_items[i]?.door_type?.product_type,
              is_child: false,
              is_second_child: false,
              parent_uuid: null,
              parent_second_uuid: null,
              has_child: false,
              has_second_child: false,
              sum_rows: false,
              show_price: false,
              show_all: false,
              show_unit: false,
              show_quantity: false,
              show_rate: false,
              show_discount: false,
              show_total: false,
              show_indeterminate: false,
              discount_value: 0,
              custom_val: null,
              qty_array: [],
              discount_type: 1,
              prev_line_item_id: selected_line_items[i]?.prev_line_item_id,
              customer: selected_line_items[i]?.customer,
              product: selected_line_items[i]?.door_type?.product,
              product_id: selected_line_items[i]?.door_type?.product_id,
              product_type: selected_line_items[i]?.door_type?.product_type,
              description: selected_line_items[i]?.door_type?.description,
              warranty: {},
              rate: 0,
              selling_cost: 0,
              quantity: 1,
              uom: null,
              unit: "unit",
              total: 0,
              acc_total: 0,
            });
          }

          if (!(uuid in pre_line_item)) {
            pre_line_item[uuid] = new Array();
          }

          if (!Array.isArray(pre_line_item[uuid])) {
            pre_line_item[uuid] = new Array();
          }

          const sel_item = cloneDeep(selected_line_items[i]);
          
          pre_line_item[uuid].push({
            id: sel_item.product_id,
            index: uuidv4(),
            uuid: uuid,
            serial_no: sel_item?.serial_no,
            size: sel_item?.part_number,
            location: sel_item?.location,
            qty: 1,
            width: sel_item?.width,
            height: sel_item?.height,
            price: sel_item?.selling_cost,
            total: sel_item?.selling_cost,
            request_type: this.request_type,
          });
        }

        for (let z = 0; z < door_type.length; z++) {
          if (
            door_type[z].index in pre_line_item &&
            Array.isArray(pre_line_item[door_type[z].index])
          ) {
            door_type[z].qty_array = pre_line_item[door_type[z].index];
            door_type[z].quantity = pre_line_item[door_type[z].index].length;
            door_type[z].total = pre_line_item[door_type[z].index].reduce(
              (acc, item) => acc + item.price,
              0
            );
          }
        }

        console.log(door_type, "door_type")

        this.$emit("update-values", door_type);
      } else {
        this.$emit("update-values", selected_line_items);
      }

      this.$emit("close", true);
    },
    update_all_line_item(status) {
      this.select_all = status;
      for (let i = 0; i < this.lDBLineItem.length; i++) {
        this.lDBLineItem[i].status = status;
      }
    },
    filter_cat(param) {
      this.category = param;
      this.find_items();
    },
    filter_alpha(param) {
      this.alphabet = param;
      this.find_items();
    },
  },
  mounted() {
    if (this.productDialog) {
      this.find_items();
    }
  },
  computed: {
    ...mapGetters([
      "vCustomerId",
      "lDBLineItem",
      "lDoorTypeList",
      "lItemAlpha",
      "lProcessing",
      "lItemCategory",
    ]),
    dialog_width() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 80);
    },
  },
};
</script>
